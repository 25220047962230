export const zoric = {
	name: 'Vaso Zorić ?-?',
	children: [
		{
			name: 'Deva Zorić ?-?',
			children: [
				{
					name: 'Dane Zorić ?-?',
				},
				{
					name: 'Luka Zorić ?-?',
				},
			],
		},
		{
			name: 'Anđa Zorić 1868 - 1941',
			children: [
				{
					name: 'Petar Zorić 1902 - 1984',
					children: [
						{
							name: 'Anđa Zorić 1897 - 1943',
							children: [
								{ name: 'Savka Zorić 1926 - 1943' },
								{ name: 'Jelka Zorić 1929 - 1943' },
								{ name: 'Milan Zorić 1933 - 1943' },
								{ name: 'Branko Zorić 1931 - 1943' },
								{ name: 'Danica Zorić 1938 - 1943' },
								{ name: 'Dragica Zorić 1938 - 1943' },
								{
									name: 'Dane Zorić ? - ?',
									children: [
										{
											name: 'Soka Zorić ? - ?',
											children: [
												{
													name: 'Milan Zorić 1947 - ',
													children: [
														{
															name: 'Milka Zorić ? - ?',
															children: [
																{ name: 'Tanja Zorić 1974 - 1988' },
																{
																	name: 'Dana Zorić ? - ',
																	children: [
																		{ name: 'Gabriel Shultz ? - ' },
																		{ name: 'Tanja Zinda ? - ' },
																	],
																},
																{
																	name: 'Valerija Zorić ? - ',
																	children: [{ name: 'Lili Zorić ? - ' }],
																},
															],
														},
													],
												},
												{
													name: 'Ilija Zorić 1954 - ',
													children: [
														{
															name: 'Đurđica Zorić 1960 -',
															children: [
																{ name: 'Neven Zorić ? - ?' },
																{
																	name: 'Dario Zorić ? - ?',
																	children: [
																		{
																			name: 'Carla Zorić ? - ?',
																			children: [
																				{ name: 'Mateo Zorić ? - ?' },
																				{ name: 'Calojero Zorić ? - ?' },
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Bogdan Zorić 1949 - ',
													children: [
														{
															name: 'Senada Zorić 1960 -',
															children: [
																{ name: 'Dejan Zorić ? - ?' },
																{ name: 'Damir Zorić ? - ?' },
																{
																	name: 'Lidija Sterling ? - ?',
																	children: [
																		{
																			name: 'Sasha Sterling ? - ?',
																		},
																	],
																},
															],
														},
													],
												},
											],
										},
									],
								},
								{
									name: 'Luka Zorić ? - ?',
									children: [
										{
											name: 'Olgica Zorić ? - ?',
											children: [
												{
													name: 'Danica Zorić ? - ?',
													children: [
														{
															name: 'Aleksandar  Manot ? - ?',
															children: [
																{ name: 'Tanja ? - ?' },
																{ name: '? ? - ?' },
															],
														},
													],
												},
											],
										},
									],
								},
							],
						},
						{
							name: 'Mika Zorić ? - ?',
							children: [
								{
									name: 'Savka Zorić 1940 -',
									children: [
										{
											name: 'Dragan Dakić ? -',
											children: [
												{ name: 'Miodrag Dakić 1974 -' },
												{
													name: 'Tihomir Dakić 1980 -',
													children: [
														{
															name: 'Nadina Dakić ? -',
															children: [{ name: 'Tihana Dakić ? -' }],
														},
													],
												},
											],
										},
									],
								},
								{
									name: 'Jelka Zorić 1947 -',
									children: [
										{
											name: 'Petar Trkulja  1940 - 2000',
											children: [
												{
													name: 'Saša Trkulja 1967-',
													children: [{ name: 'Daliborka Trkulja 1976 - ' }],
												},
												{ name: 'Svjetlana Trkulja 1972-' },
												{
													name: 'Jovica Trkulja 1980-',
													children: [
														{
															name: 'Jasna Trkulja ? -',
															children: [{ name: 'Lea Trkulja 2005 -' }],
														},
														{
															name: 'Maja Trkulja 1977 -',
															children: [{ name: 'Teodor Trkulja 2015 -' }],
														},
													],
												},
											],
										},
									],
								},
								{
									name: 'Mara Zorić 1949 -',
									children: [
										{
											name: 'Slobodan Vignjević 1947 - 2015',
											children: [
												{
													name: 'Dane Vignjević ? -',
													children: [
														{
															name: 'Dragana Vignjević ? - ',
															children: [
																{
																	name: 'Kristina Vignjević ? - ',
																	children: [{ name: 'River Vignjević ? - ' }],
																},
																{ name: 'Luka Vignjević ? - ' },
															],
														},
													],
												},
												{
													name: 'Luka Vignjević ? -',
													children: [
														{
															name: 'Judy Vignjević ? - ',
															children: [
																{
																	name: 'Zoran Vignjević ? - ',
																},
																{ name: 'Marica Vignjević ? - ' },
															],
														},
													],
												},
											],
										},
									],
								},
							],
						},
					],
				},
				{
					name: 'Lazo Zorić ? - ?',
					children: [
						{
							name: 'Marica Zorić  ? - 1943',
							children: [
								{ name: 'Mileva Zorić 1937 - 1943' },
								{ name: 'Boja Zorić 1940 - 1943' },
							],
						},
						{
							name: 'Staka Zorić ? - ?',
							children: [{ name: 'Dragan Zorić ? - ' }, { name: 'Mišo  ? - ' }],
						},
					],
				},
				{
					name: 'Ilija Zorić ? - ?',
					children: [
						{ name: 'Olgica Zorić ? -', children: [{ name: 'Ana Zorić ? -' }] },
					],
				},
				{
					name: 'Živko Zorić ? - ?',
					children: [
						{
							name: 'Bogdan Zorić ? - ?',
							children: [
								{ name: 'Nada Zorić ? - ?' },
								{ name: 'Tihomir Zorić ? - ?' },
							],
						},
						{ name: 'Neđo', children: [{ name: '? ? - ?' }] },
					],
				},
			],
		},
	],
};

export const vignjevic = {
	name: 'Vaso Vignjević 1830 - ?',
	children: [
		{
			name: '? Vignjević ? - ?',
			children: [
				{
					name: 'Dane Vignjević 1852 - 1927',
					children: [
						{
							name: 'Joka Vignjević 1853 - 1908',
							children: [
								{
									name: 'Ilija Vignjević 1883 - 1941',
									children: [
										{
											name: 'Sava Vignjević ? - 1938',
											children: [
												{
													name: 'Bogdan Vignjević 1919 - 1998',
													children: [
														{
															name: 'Vida Vignjević 1921 - 1987',
															children: [
																{
																	name: 'Milka Vignjević 1940 - ',
																	children: [
																		{
																			name: 'Sava Matijević 1933 - 2015',
																			children: [
																				{
																					name: 'Rada Matijević 1960 - ',
																					children: [
																						{
																							name: 'Miloš Milanović ? - ',

																							children: [
																								{
																									name: 'Dalibor Milanović',
																									children: [
																										{
																											name: 'Katarina Milanović',
																											children: [
																												{
																													name: 'Teodora Milanović 2023 - ',
																												},
																											],
																										},
																									],
																								},
																								{
																									name: 'Tihomir Milanović',
																									children: [
																										{
																											name: 'Milica Milanović',
																											children: [
																												{
																													name: 'Jakob Milanović 2023 - ',
																												},
																											],
																										},
																									],
																								},
																							],
																						},
																					],
																				},
																				{
																					name: 'Ratko Matijević 1965 - ',
																					children: [
																						{
																							name: 'Ljiljana Matijević',
																							children: [
																								{
																									name: 'Marija Matijević 1988 - ',
																									children: [
																										{
																											name: 'Goran Bibić',
																											children: [
																												{
																													name: 'Nikolina Bibić 2015 - ',
																												},
																												{
																													name: 'Stefan Bibić 2019 - ',
																												},
																											],
																										},
																									],
																								},
																								{
																									name: 'Nebojša Matijević 1991 - ',
																									children: [
																										{
																											name: 'Jeisa Javor',
																											children: [
																												{
																													name: 'Zek Javor 2018 - ',
																												},
																												{
																													name: 'Zeerina Javor 2020 - ',
																												},
																											],
																										},
																									],
																								},
																								{
																									name: 'Mirjana Matijević 1993 - ',
																								},
																								{
																									name: 'Mirko Matijević 2009 - ',
																								},
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
																{
																	name: 'Slobodan Vignjević 1947 - 2015',
																	children: [
																		{
																			name: 'Mara Vignjević 1949 - ',
																			children: [
																				{
																					name: 'Dane Vignjević ? -',
																					children: [
																						{
																							name: 'Dragana Vignjević ? - ',
																							children: [
																								{
																									name: 'Kristina Vignjević ? - ',
																									children: [
																										{
																											name: 'River Vignjević ? - ',
																										},
																									],
																								},
																								{ name: 'Luka Vignjević ? - ' },
																							],
																						},
																					],
																				},
																				{
																					name: 'Luka Vignjević ? -',
																					children: [
																						{
																							name: 'Judy Vignjević ? - ',
																							children: [
																								{
																									name: 'Zoran Vignjević ? - ',
																								},
																								{
																									name: 'Marica Vignjević ? - ',
																								},
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
																{
																	name: 'Soka Vignjević 1949 - ',
																	children: [
																		{
																			name: 'Damjan Mandić',
																			children: [
																				{
																					name: 'Danijela Mandić 1972 - ',
																					children: [
																						{
																							name: 'Zoran Medved',
																							children: [
																								{ name: 'Andreja Medved 1998' },
																								{
																									name: 'Anastasija Medved 2002',
																								},
																							],
																						},
																					],
																				},
																				{
																					name: 'Maja Mandić 1975 - ',
																					children: [
																						{
																							name: 'Goran Bitić',
																							children: [
																								{ name: 'Lazar Bitić 2009 - ' },
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Soka Vignjević 1923 - 2010',
													children: [
														{
															name: 'Dane Zorić 1920 - 1959',
															children: [
																{
																	name: 'Milan Zorić 1947 - ',
																	children: [
																		{
																			name: 'Milka Zorić ? - ?',
																			children: [
																				{ name: 'Tanja Zorić 1974 - 1988' },
																				{
																					name: 'Dana Zorić ? - ',
																					children: [
																						{ name: 'Gabriel Shultz ? - ' },
																						{ name: 'Tanja Zinda ? - ' },
																					],
																				},
																				{
																					name: 'Valerija Zorić ? - ',
																					children: [
																						{ name: 'Lili Zorić ? - ' },
																					],
																				},
																			],
																		},
																	],
																},
																{
																	name: 'Ilija Zorić 1954 - ',
																	children: [
																		{
																			name: 'Đurđica Zorić 1960 -',
																			children: [
																				{ name: 'Neven Zorić ? - ?' },
																				{
																					name: 'Dario Zorić ? - ?',
																					children: [
																						{
																							name: 'Carla Zorić ? - ?',
																							children: [
																								{ name: 'Mateo Zorić ? - ?' },
																								{
																									name: 'Calojero Zorić ? - ?',
																								},
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
																{
																	name: 'Bogdan Zorić 1949 - ',
																	children: [
																		{
																			name: 'Senada Zorić 1960 -',
																			children: [
																				{ name: 'Dejan Zorić ? - ?' },
																				{ name: 'Damir Zorić ? - ?' },
																				{
																					name: 'Lidija Sterling ? - ?',
																					children: [
																						{
																							name: 'Sasha Sterling ? - ?',
																						},
																					],
																				},
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Jovo Vignjević 1930 - 1995',
													children: [
														{
															name: 'Zorka Vignjević 1924 - 1992',
															children: [
																{
																	name: 'Milovan Vignjević 1954 - 2009',
																	children: [
																		{
																			name: 'Marica Vignjević',
																			children: [
																				{ name: 'Dragana Vignjević' },
																				{ name: 'Milana Vignjević' },
																			],
																		},
																		{ name: 'Aleksandra Vignjević' },
																	],
																},
															],
														},
													],
												},
												{
													name: 'Dane Vignjević 1924 - 1991',
													children: [
														{
															name: 'Darinka Vignjević 1927 - 1988',
															children: [
																{
																	name: 'Dušan Vignjević 1949 - ',
																	children: [
																		{
																			name: 'Jadranka Radšel 1953 - ',
																			children: [
																				{
																					name: 'Snježana Vignjević 1973 - ',
																					children: [
																						{
																							name: 'Boris Pajić 1963 - 2006',
																							children: [
																								{
																									name: 'Iza Pajić 1998 - ',
																									children: [
																										{ name: 'Jan Jurišić' },
																									],
																								},
																							],
																						},
																					],
																				},
																			],
																		},
																		{ name: 'Tatjana Rojšek 1947 - ' },
																	],
																},
																{
																	name: 'Dušanka Vignjević 1955 - ',
																	children: [
																		{
																			name: 'Marijan Karo 1954 - ',
																			children: [
																				{ name: 'Dušan karo 1980 - ' },
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{ name: 'Pepo Vignjević ? - ?' },
											],
										},
									],
								},
								{
									name: 'Petar Vignjević 1898 - 1973',
									children: [
										{
											name: 'Jeka Vignjević ? - ?',
											children: [
												{
													name: 'Đurađ Vignjević 1925 - ',
													children: [
														{
															name: 'Mileva Vignjević ? - ?',
															children: [
																{
																	name: 'Ratko Vignjević 1949 -',
																	children: [
																		{
																			name: 'Savka Vignjević ? -',
																			children: [
																				{ name: 'Petar Vignjević 1978 - ' },
																				{ name: 'Neand Vignjević 1985 - ' },
																			],
																		},
																	],
																},
																{ name: 'Slavica Vignjević 1951 - ' },
																{ name: 'Dušan Vignjević 1955 - ' },
																{
																	name: 'Petar Vignjević 1957 - ',
																	children: [
																		{
																			name: 'Rada Vignjević',
																			children: [
																				{
																					name: 'Jovana Vignjević 1989 - ',
																					children: [
																						{ name: 'Mladen Todorović' },
																					],
																				},
																				{ name: 'Dušan Vignjević 1995 - ' },
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Dušan Vignjević 1928 - 2022',
													children: [
														{
															name: 'Jagoda Vignjević ? - ?',
															children: [
																{
																	name: 'Milorad Vignjević',
																	children: [
																		{
																			name: 'Milica Vignjević',
																			children: [
																				{
																					name: 'Jelena Vignjević 1984 - 2005',
																				},
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Jeka Vignjević ? - ?',
													children: [
														{
															name: '? Ćalić',
															children: [
																{ name: 'Jovan Ćalić' },
																{
																	name: 'Jovanka Ćalić',
																	children: [
																		{
																			name: '? Vasiljev',
																			children: [
																				{
																					name: 'Vladimir Vasiljev 1974 - ',
																					children: [
																						{
																							name: 'Julija Vasiljev',
																							children: [
																								{ name: 'Milan Vasiljev' },
																							],
																						},
																					],
																				},
																				{
																					name: 'Jelena Vasiljev 1976 - ',
																					children: [
																						{
																							name: 'Edoardo Tomaselli',
																							children: [
																								{ name: 'Emilija Tomaselli' },
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
															],
														},
													],
												},
											],
										},
									],
								},
								{
									name: 'Dragica Vignjević 1904 - 1986',
									children: [
										{
											name: 'Stevo Radošević 1904 - 1944',
											children: [
												{
													name: 'Luba Radošević 1938 - ',
													children: [
														{
															name: '? Labus',
															children: [
																{ name: 'Rade Labus' },
																{
																	name: 'Smilja Labus',
																	children: [
																		{
																			name: 'Mile Čubrilo',
																			children: [
																				{
																					name: 'Mirjana Čubrilo',
																					children: [
																						{
																							name: 'Nenad Atelj',
																							children: [{ name: 'Vuk Atelj' }],
																						},
																					],
																				},
																				{
																					name: 'Danijela Čubrilo',
																					children: [
																						{
																							name: 'Petar Zgonjanin',
																							children: [
																								{ name: 'Viktorija Zgonjanin' },
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Dane Radošević 1938 - 2007',
													children: [
														{
															name: 'Koviljka Radošević 1941 - 2003',
															children: [
																{
																	name: 'Stevo Radošević 1961 - ',
																	children: [
																		{
																			name: 'Dušica Bates',
																			children: [
																				{ name: 'Danijel Radošević' },
																				{
																					name: 'Slobodan Radošević',
																					children: [
																						{
																							name: 'Danijela Radošević',
																							children: [
																								{ name: 'Valentina Radošević' },
																								{ name: 'Kristina Radošević' },
																								{ name: 'Adrijana Radošević' },
																								{
																									name: 'Vuk Radošević 2020 - ',
																								},
																							],
																						},
																					],
																				},
																				{
																					name: 'Olgica Radošević',
																					children: [
																						{
																							name: 'Zoran Beronja',
																							children: [
																								{ name: 'Luka Beronja' },
																								{ name: 'Marija Beronja' },
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
																{
																	name: 'Dragan Radošević 1963 - ',
																	children: [
																		{
																			name: 'Dijana Radošević',
																			children: [
																				{
																					name: 'Dejan Radošević',
																					children: [
																						{ name: 'Milica Radošević' },
																					],
																				},
																			],
																		},
																		{
																			name: 'Dragana Radošević',
																			children: [
																				{
																					name: 'Nikola Barišić 1986 - 2019',
																					children: [
																						{ name: 'David Barišić' },
																						{ name: 'Sara Barišić' },
																					],
																				},
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Rade Radošević 1943 - 2010',
													children: [
														{
															name: 'Gospova Radošević',
															children: [
																{
																	name: 'Dragica Radošević',
																	children: [
																		{
																			name: 'Predrag Šarko',
																			children: [{ name: 'Marko Šarko 2078' }],
																		},
																		{
																			name: 'Miloš Milinković 1961 - 1994',
																			children: [
																				{ name: 'Ilija Milinković 1988 - ' },
																				{ name: 'Ivana Milinković 1993 - ' },
																			],
																		},
																	],
																},
															],
														},
													],
												},
											],
										},
									],
								},
							],
						},
					],
				},
				{
					name: 'David Vignjević 1880 - 1941',
					children: [
						{
							name: 'Mika Vignjević ? - ?',
							children: [
								{
									name: 'Stevan Vignjević ? - 1941',
									children: [{ name: 'Sava Vignjević ? - ?' }],
								},
								{
									name: 'Marija Vignjević ? - 1942',
									children: [
										{
											name: 'Jovo Krkez ? - 1985',
											children: [
												{
													name: 'Soka Kerkez 1927 - 1989',
													children: [
														{
															name: 'Rade Radošević',
															children: [
																{
																	name: 'Slavica Radošević',
																	children: [
																		{
																			name: 'Mile Paldrmić',
																			children: [
																				{
																					name: 'Dragan Paldrmić',
																					children: [
																						{
																							name: 'Cherie Zander',
																							children: [
																								{ name: 'Dušan Paldrmić' },
																								{ name: 'Danilo Paldrmić' },
																								{ name: 'Sofija Paldrmić' },
																							],
																						},
																					],
																				},
																				{ name: 'Nenad Paldrmić' },
																			],
																		},
																	],
																},
																{
																	name: 'Slavko Radošević 1952',
																	children: [
																		{
																			name: 'Stevanka Radošević 1953',
																			children: [
																				{
																					name: 'Ivana Radošević',
																					children: [
																						{
																							name: 'Milanko Brdar',
																							children: [
																								{ name: 'Nenad Brdar' },
																								{ name: 'Aleksandar Brdar' },
																								{ name: 'Milica Brdar' },
																							],
																						},
																					],
																				},
																				{
																					name: 'Jelena Radošević',
																					children: [
																						{
																							name: 'Predrag Zagorčić',
																							children: [
																								{ name: 'Lenka Zagorčić' },
																								{ name: 'Emilija Zagorčić' },
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Mika Kerkez 1934',
													children: [
														{
															name: 'Radonja Vujošević 1923 - 2003',
															children: [
																{
																	name: 'Miodrag Vujošević 1956',
																	children: [
																		{
																			name: '? Vujošević',
																			children: [
																				{ name: 'Veljko Vujošević' },
																				{ name: 'Ivana Vujošević' },
																			],
																		},
																	],
																},
																{ name: 'Rada Vujošević 1958 - 1960' },
																{
																	name: 'Olivera Vujošević',
																	children: [
																		{
																			name: '? Radonjić',
																			children: [
																				{ name: 'Milena Radonjić' },
																				{ name: 'Luka Radonjić' },
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Nikola Kerkez',
													children: [
														{
															name: '? Kerkez',
															children: [
																{ name: 'Sveto Kerkez' },
																{
																	name: 'Dragan Kerkez',
																	children: [
																		{
																			name: '? ?',
																			children: [
																				{
																					name: 'Srđan Kerkez',
																					children: [
																						{
																							name: 'Rada Kerkez',
																							children: [
																								{ name: 'Nenad Kerkez' },
																								{ name: 'Nina Kerkez' },
																							],
																						},
																					],
																				},
																			],
																		},
																		{
																			name: 'Spomenka Kerkez',
																			children: [
																				{
																					name: 'Slađana Kerkez',
																					children: [
																						{
																							name: 'Milan Mlinčan',
																							children: [
																								{ name: 'Filip Mlinčan' },
																								{ name: 'Sara Mlinčan' },
																							],
																						},
																					],
																				},
																				{ name: '? Kerkez' },
																				{ name: 'Igor Kerkez' },
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Savka Kerkez',
													children: [
														{
															name: '? ?',
															children: [
																{
																	name: 'Nikola Krekez',
																	children: [
																		{
																			name: '? Kerkez',
																			children: [
																				{
																					name: 'Slobodanka Krekez',
																					children: [
																						{
																							name: 'Dejan Pilipović',
																							children: [
																								{ name: 'Nikola Pilipović' },
																								{ name: 'Nemanja Pilipović' },
																							],
																						},
																					],
																				},
																				{
																					name: 'Branka Krekez',
																					children: [
																						{
																							name: 'Drgan Vještica',
																							children: [
																								{
																									name: 'Višnja Vještica 2009',
																								},
																								{ name: 'Luka Vještica 2011' },
																								{
																									name: 'Aleksej Vještica 2016',
																								},
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Smiljka Krekez',
													children: [
														{
															name: 'Slobodan Ljiljak',
															children: [
																{
																	name: 'Mišo Ljiljak',
																	children: [
																		{
																			name: 'Dragica ljiljak',
																			children: [
																				{ name: 'Jasna Ljiljak' },
																				{ name: 'Slobodan Ljiljak' },
																			],
																		},
																	],
																},
																{
																	name: 'Slobodanka Ljiljak',
																	children: [
																		{
																			name: '? Mulalić',
																			children: [
																				{
																					name: 'Jasmina Mulalić',
																					children: [
																						{
																							name: 'Nenad Petković',
																							children: [
																								{ name: 'Klara Petković' },
																								{ name: '? Petković' },
																								{ name: 'Filip Petković' },
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Boško Kerkez',
													children: [
														{
															name: 'Marija Krekez',
															children: [
																{
																	name: 'Marija Kerkez',
																	children: [{ name: 'Dragan Đaković' }],
																},
																{
																	name: 'Mihajlo Kerkez',
																	children: [
																		{
																			name: 'Cvetanka Kerkez',
																			children: [
																				{
																					name: 'Goran Kerkez',
																					children: [
																						{
																							name: 'Tina Kerkez',
																							children: [
																								{ name: 'Viktor Kerkez' },
																							],
																						},
																					],
																				},
																			],
																		},
																		{
																			name: '? ?',
																			children: [
																				{ name: 'Nenad Kerkez' },
																				{ name: 'Mirjana Kerkez' },
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Milka Kerkez',
													children: [
														{
															name: 'Milan Babić',
															children: [
																{
																	name: 'Branko Babić',
																	children: [
																		{
																			name: '? Babić',
																			children: [
																				{ name: 'Nataš Babić' },
																				{ name: 'Boris Babić' },
																			],
																		},
																	],
																},
																{ name: 'Branka Babić' },
															],
														},
													],
												},
											],
										},
									],
								},
								{
									name: 'Soka Vignjević ? - ?',
									children: [
										{
											name: 'Božo Balać ? - ?',
											children: [
												{
													name: 'Draga Balać 1926 - 2000',
													children: [
														{
															name: 'Mićo Grubiša 1928 - 1988',
															children: [
																{ name: 'Slavko Grubiša 1946 - 1946' },
																{
																	Name: 'Slobodanka Grubiša  1955 - ',
																	children: [
																		{
																			name: 'Milan Opačić 1954 -',
																			children: [
																				{
																					name: 'Vedrana Opačić 1980',
																					children: [
																						{
																							name: 'Danko Krsmanović',
																							children: [
																								{
																									name: 'Aksentije Krsmanović 2014',
																								},
																							],
																						},
																					],
																				},
																				{ name: 'Svjetlana Opačić 1984' },
																			],
																		},
																	],
																},
																{
																	Name: 'Jadranka Grubiša  1959 - ',
																	children: [
																		{
																			name: 'Drago Pemac',
																			children: [
																				{
																					name: 'Dragana Pemac 1985',
																					children: [
																						{ name: 'Mila Pemac 2023' },
																					],
																				},
																				{ name: 'Tamara Pemac 1992' },
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Mika Balać 1932 - ',
													children: [
														{
															name: 'Nikola Džepina',
															children: [
																{
																	name: 'Milan Džepina',
																	children: [
																		{
																			name: 'Snježana Džepina',
																			children: [
																				{
																					name: 'Vanja Džepina',
																					children: [
																						{
																							name: 'Tijana Džepina',
																							children: [
																								{ name: 'Nadja Džepina' },
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
																{
																	name: 'Slavica Džepina',
																	children: [
																		{
																			name: 'Jadranko Rokvić',
																			children: [
																				{
																					name: 'Boro Rokvić',
																					children: [
																						{
																							name: '? Rokvić',
																							children: [
																								{ name: 'Filip Rokvić' },
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Đuro Balać',
													children: [
														{
															name: 'Koviljka Balać',
															children: [
																{ name: 'Mira Balać' },
																{ name: 'Boško Balać' },
																{ name: 'Draga Balać' },
																{
																	name: 'Mika Balać',
																	children: [
																		{
																			name: 'Milan Ševo',
																			children: [
																				{
																					name: 'Žarko Ševo',
																					children: [
																						{
																							name: 'Vera Ševo',
																							children: [
																								{ name: 'Luka Ševo' },
																								{ name: 'Lara Ševo' },
																								{ name: '? Ševo' },
																							],
																						},
																					],
																				},
																				{ name: 'Marko Ševo' },
																				{ name: 'Tihana Ševo' },
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Đuja Balać',
													children: [
														{
															name: 'Bogdan Grubiša',
															children: [
																{
																	name: 'Ilija Grubiša',
																	children: [
																		{
																			name: 'Zora Grubiša',
																			children: [
																				{ name: 'Igor Grubiša' },
																				{ name: 'Goran Grubiša' },
																			],
																		},
																	],
																},
																{ name: 'Milka Grubiša' },
																{ name: 'Kova Grubiša' },
																{ name: 'Bosa Grubiša' },
																{ name: 'Stoja Grubiša' },
																{
																	name: 'Mika Grubiša',
																	children: [
																		{
																			name: '? Manojlović',
																			children: [
																				{ name: 'Dražen Manojlović' },
																				{
																					name: 'Andrea Manojlović',
																					children: [
																						{
																							name: 'David Polić',
																							children: [
																								{ name: 'Jovan Polić' },
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Ilija Balać',
													children: [
														{
															name: 'Borka Balać',
															children: [
																{
																	name: 'Veljko Balać',
																	children: [
																		{
																			name: 'Sanja Balać',
																			children: [
																				{ name: 'Marko Balać' },
																				{ name: 'Ksenija Balać' },
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Savo Balać',
													children: [
														{
															name: 'Ljubica Balać',
															children: [
																{ name: 'Mišo Balać' },
																{
																	name: 'Mara Balać',
																	children: [
																		{
																			name: 'Nikola Radaković',
																			chidren: [
																				{
																					name: 'Žarko Radaković',
																					children: [
																						{
																							name: 'Marija Radaković',
																							children: [
																								{ name: '? Radaković' },
																							],
																						},
																					],
																				},
																				{
																					name: 'Željko Radaković',
																					children: [
																						{
																							name: 'Jelena Radaković',
																							children: [
																								{ name: 'Luka Radaković' },
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
															],
														},
													],
												},
											],
										},
									],
								},
								{
									name: 'Savka Vignjević ? - ?',
									children: [
										{
											name: '? Obradović',
											children: [
												{
													name: 'Stevo Obradović ? - ?',
													children: [
														{
															name: 'Marija Obradović ? - ?',
															children: [{ name: 'Đuro Obradović' }],
														},
													],
												},
												{
													name: 'Maniša Obradović ? - ?',
													children: [
														{
															name: 'Milja Obradović ? - ?',
															children: [
																{
																	name: 'Darko Obradović',
																	children: [
																		{
																			name: 'Marela Obradović',
																			children: [{ name: 'Vanja Obradović' }],
																		},
																	],
																},
															],
														},
													],
												},
											],
										},
										{
											name: '? Kerkez ? - ?',
											children: [
												{
													name: 'Gojko Kerkez',
													children: [
														{ name: 'Mile Krekez' },
														{ name: 'Nevenko Krekez' },
													],
												},
												{
													name: 'Jovo Kerkez',
													children: [{ name: 'Brane Kerkez' }],
												},
												{
													name: 'Sava Kerkez',
													children: [
														{
															name: '? Kovačević',
															children: [{ name: 'Brane Kovačević' }],
														},
													],
												},
											],
										},
									],
								},
							],
						},
					],
				},
				{
					name: 'Luka Vignjević ? - ?',
					children: [
						{
							name: '? Vignjević ? - ?',
							children: [
								{
									name: 'Nikola Vignjević 1889 - 1941',
									children: [
										{
											name: 'Mika Vignjević 1890 - 1981',
											children: [
												{
													name: 'Bojana Vignjević 1923 - 2002',
													children: [
														{
															name: 'Stevo Stakić 1926 - 1970',
															children: [
																{ name: 'Petar Stakić' },
																{
																	name: 'Branka Stakić',
																	children: [
																		{
																			name: 'Milomir Bosnić',
																			children: [
																				{ name: 'Dragan Bosnić' },
																				{ name: 'Ivica Bosnić' },
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Mika Vignjević 1930 - ?',
													children: [
														{
															name: 'Nikola Trifunov',
															children: [
																{
																	name: 'Dušan Trifunov',
																	children: [
																		{
																			name: 'Slavica Trifunov',
																			children: [
																				{
																					name: 'Aleksandar Trifunov 1978',
																					children: [
																						{
																							name: '? Trifynov',
																							children: [
																								{ name: 'Lea Trifunov 2009' },
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
																{ name: 'Goran Trifunov 1981' },
															],
														},
													],
												},
												{
													name: 'Boško Vignjević 1932 - 2009',
													children: [
														{
															name: 'Rosa Vignjević 1936 - 2006',
															children: [
																{
																	name: 'Željka Vignjević 1962 - 2021',
																	children: [
																		{
																			name: 'Miro Maleš',
																			children: [
																				{ name: 'Anđelko Maleš 2003' },
																			],
																		},
																	],
																},
																{
																	name: 'Dragan Vignjević 1963',
																	children: [
																		{
																			name: 'Radmila Vignjević',
																			children: [
																				{
																					name: 'Sanja Vignjević 1968',
																					children: [
																						{
																							name: 'Shawn Kurt Beyer',
																							children: [
																								{
																									name: 'Maxim Kurt Beyer 2021',
																								},
																							],
																						},
																					],
																				},
																				{ name: 'Dejan Vignjević 1990' },
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Stevo Vignjević 1934 - 1999',
													children: [
														{
															name: 'Miroslava Vignjević 1937 - 2021',
															children: [
																{
																	name: 'Nikola Vignjević 1959',
																	children: [
																		{
																			name: 'Vesna Vignjević',
																			children: [
																				{
																					name: 'Mirjana Vignjević',
																					children: [
																						{
																							name: 'Milorad Vijatov',
																							children: [
																								{ name: 'Marija Vijatov 2008' },
																								{ name: 'Milena Vijatov 2011' },
																								{
																									name: 'Mihajlo Vijatov 2017',
																								},
																							],
																						},
																					],
																				},
																				{
																					name: 'Stevan Vignjević 1987',
																					children: [
																						{
																							name: 'Bojana Vignjević',
																							children: [
																								{
																									name: 'Nikola Vignjević 2013',
																								},
																								{
																									name: ' Katarina Vignjević 2017',
																								},
																							],
																						},
																					],
																				},
																			],
																		},
																		{ name: 'Žaklina Đorđević' },
																	],
																},
																{
																	name: 'Jelica Vignjević 1961',
																	children: [
																		{
																			name: 'Filip Nanusevski 1950',
																			children: [
																				{
																					name: 'Zoran Nanusevski 1980',
																					children: [
																						{
																							name: 'Adrijana Nanusevski',
																							children: [
																								{ name: 'Ana Nanusevski 2017' },
																								{
																									name: 'Sara Nanusevski 2020',
																								},
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Dara Vignjević 1938 - ?',
													children: [
														{
															name: 'Milan Srdić',
															children: [
																{
																	name: 'Marko Srdić',
																	children: [
																		{
																			name: 'Erzika Srdić',
																			children: [
																				{
																					name: 'Igor Srdić',
																					children: [
																						{
																							name: 'Adela Srdić',
																							children: [{ name: '? Srdić' }],
																						},
																					],
																				},
																				{
																					name: 'Maja Srdić',
																					children: [
																						{
																							name: 'Anđelko Kracković',
																							children: [
																								{ name: 'Petar Kracković' },
																								{ name: 'Mia Kracković' },
																							],
																						},
																					],
																				},
																			],
																		},
																	],
																},
																{
																	name: 'Mirjana Srdić',
																	children: [
																		{
																			name: 'Obrad Lukić',
																			children: [
																				{ name: 'Vlado Lukić' },
																				{ name: 'Ivana Lukić' },
																			],
																		},
																	],
																},
															],
														},
													],
												},
												{
													name: 'Dane Vignjević 1940',
													children: [
														{
															name: 'Ljilja Vignjević',
															children: [
																{
																	name: 'Slobodan Vignjević 1967',
																	cjildren: [
																		{
																			name: 'Dijana Vignjević',
																			children: [
																				{ name: 'Filomena Vignjević' },
																			],
																		},
																	],
																},
																{ name: 'Jelena Vignjević 1973' },
															],
														},
													],
												},
												{ name: 'Sava Vignjević' },
											],
										},
									],
								},
								{
									name: 'Marko Vignjević 1898 - 1975',
									children: [
										{
											name: 'Dara Vignjević 1900 - 1964',
											children: [
												{ name: 'Soka Vignjević 1926 - 1992' },
												{
													name: 'Jela Vignjević 1923 - 2013',
													children: [
														{
															name: 'Dušan Srdić 1930 - 2012',
															children: [
																{ name: 'Mirjana Srdić 1957' },
																{ name: 'Milan Srdić 1963' },
															],
														},
													],
												},
												{
													name: 'Jovo Vignjević',
													children: [
														{
															name: 'Ljubica Vignjević',
															children: [
																{
																	name: 'Marko Vignjević',
																	children: [
																		{
																			name: '? Vignjević',
																			children: [
																				{ name: 'Jovana Vignjević' },
																				{ name: 'Marija Vignjević' },
																			],
																		},
																	],
																},
																{
																	name: 'Milica Vignjević',
																	children: [
																		{
																			name: 'Radomir Micunović',
																			children: [{ name: 'Boris Micunović' }],
																		},
																	],
																},
															],
														},
													],
												},
											],
										},
									],
								},
								{
									name: 'Mile Vignjević 1900 - 1984',
									children: [
										{ name: 'Đuro Vignjević 1919 - 1943' },
										{ name: 'Ilija Vignjević 1919 - 1945' },
										{
											name: 'Sofija Vignjević 1938 - ?',
											children: [
												{
													name: 'Milan Obradović',
													children: [
														{
															name: 'Svetlana Obradović',
															children: [{ name: 'Jelena Obradović' }],
														},
														{
															name: 'Snežana Obradović',
															children: [
																{
																	name: '? Grbić',
																	children: [
																		{
																			name: 'Dunja Grbić',
																			children: [
																				{
																					name: '? ?',
																					children: [{ name: 'Srna ?' }],
																				},
																			],
																		},
																		{ name: 'Milan Grbić' },
																	],
																},
															],
														},
													],
												},
											],
										},
										{
											name: 'Smilja Vignjević 1938 - 2009',
											children: [
												{
													name: 'Kire Micev',
													children: [
														{
															name: 'Toša Mitrov 1956 - 2019',
															children: [
																{
																	name: 'Suzana Mitrov',
																	children: [
																		{ name: 'Kire Mitrov' },
																		{ name: 'Nenad Mitrov' },
																	],
																},
															],
														},
														{
															name: 'Mile Mitrov 1958',
															children: [
																{
																	name: 'Sonja Mitrov',
																	children: [
																		{
																			name: 'Marija Mitrov',
																			children: [
																				{
																					name: 'Vase Jordanski',
																					children: [
																						{ name: '? Jordanski' },
																						{ name: '? Jordanski' },
																					],
																				},
																			],
																		},
																		{
																			name: 'Smilka Mitrov',
																			children: [
																				{
																					name: 'Dragan Bogdev',
																					children: [
																						{ name: 'Mario Bogdev' },
																						{ name: '? Bogdev' },
																						{ name: '? Bogdev' },
																					],
																				},
																			],
																		},
																	],
																},
															],
														},
													],
												},
											],
										},
										{
											name: 'Luka Vignjević',
											children: [
												{
													name: 'Olga Vignjević',
													children: [
														{
															name: 'Dragana Vignjević',
															children: [
																{
																	name: 'Slavko Čavić',
																	children: [
																		{ name: 'Sanja Čavić 1985' },
																		{ name: 'Vanja Čavić 1987' },
																	],
																},
															],
														},
													],
												},
											],
										},
									],
								},
								{ name: 'Perka Vignjević 1916 - 1943' },
							],
						},
					],
				},
			],
		},
	],
};
