import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
import { gql, useMutation } from '@apollo/client';
import { useUserContext } from '../lib/UserProvider';
import ZV from '../images/ZV-min.webp';
import Loading from '../images/loading.svg';

const SignupSchema = Yup.object().shape({
	username: Yup.string().required('Obavezno unijeti korisničko ime'),
	password: Yup.string().required('Obavezno unijeti lozinku'),
});

const LOGIN = gql`
	mutation login($password: String = "", $username: String = "") {
		login(
			input: {
				provider: PASSWORD
				credentials: { password: $password, username: $username }
			}
		) {
			authToken
			authTokenExpiration
			clientMutationId
			refreshToken
			refreshTokenExpiration
			user {
				name
				id
				email
			}
		}
	}
`;

const Login = () => {
	const [login, { data, loading, error }] = useMutation(LOGIN);
	const [user, setUser] = useUserContext();
	const navigate = useNavigate();
	//console.log(loading, error, data ? data.login : data);
	useEffect(() => {
		if (!loading && data) {
			setUser(data.login);
		}
	}, [loading, data, setUser]);
	useEffect(() => {
		if (user) {
			navigate('/postovi');
		}
	}, [user, navigate]);
	return (
		<>
			<Helmet>
				<title>Login - Plase</title>

				<meta
					name='description'
					content='Ova stranica je namijenjena internim temama porodica Zorić i
					Vignjević iz Potkrša, selo Lipa kod Bihaća.'
				/>

				<meta property='og:type' content='website' />
				<meta property='og:title' content='Login - Plase' />
				<meta
					property='og:description'
					content='Ova stranica je namijenjena internim temama porodica Zorić i
					Vignjević iz Potkrša, selo Lipa kod Bihaća.'
				/>
			</Helmet>
			<div className='flex min-h-[calc(100vh-184px)] flex-col justify-center px-6 py-12 lg:px-8 '>
				<div className='sm:mx-auto sm:w-full sm:max-w-sm'>
					<img className='mx-auto h-54 w-auto' src={ZV} alt='List lipe' />
					<h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
						Ulogujte se
					</h2>
				</div>

				<div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
					<Formik
						initialValues={{
							username: '',
							password: '',
						}}
						validationSchema={SignupSchema}
						onSubmit={async (values, { setSubmitting, resetForm }) => {
							// same shape as initial values
							await login({
								variables: {
									username: values.username,
									password: values.password,
								},
							});
							setSubmitting(false);
							resetForm();
						}}
					>
						{({ values, errors, touched }) => (
							<Form className='space-y-6'>
								<div>
									<label
										htmlFor='user'
										className='block text-sm font-medium leading-6 text-gray-900'
									>
										Email adresa
									</label>
									<div className='mt-2'>
										<Field
											id='user'
											name='username'
											type='text'
											autoComplete='current-username'
											required
											className='block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
										/>
									</div>
									{errors.username && touched.username ? (
										<div className='text-red-500 font-medium'>
											{errors.username}
										</div>
									) : null}
								</div>

								<div>
									<div className='flex items-center justify-between'>
										<label
											htmlFor='password'
											className='block text-sm font-medium leading-6 text-gray-900'
										>
											Lozinka
										</label>
									</div>
									<div className='mt-2'>
										<Field
											id='password'
											name='password'
											type='password'
											autoComplete='current-password'
											value={values.password}
											required
											className='block w-full rounded-md border-0 py-1.5 pl-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
										/>
									</div>
									{errors.password && touched.password ? (
										<div className='text-red-500 font-medium'>
											{errors.password}
										</div>
									) : null}
								</div>

								<div>
									<button
										type='submit'
										className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
									>
										Uloguj se
									</button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
				{loading && (
					<div className='lg:container mx-auto px-5 flex justify-center items-center'>
						<img src={Loading} alt='Loader' className='w-16' />
					</div>
				)}
				{error && (
					<div className='lg:container mx-auto px-5 flex justify-center items-center'>
						<p className='text-xl text-red-500'>{error.message}</p>
					</div>
				)}
			</div>
		</>
	);
};

export default Login;
