import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import Home from './pages/Home';
import Posts from './pages/Posts';
import Login from './pages/Login';
import Gallery from './pages/Gallery';
import NotFound from './pages/404';
import { useUserContext } from './lib/UserProvider';

function App() {
	const [user] = useUserContext();
	return (
		<div className='App'>
			<Routes>
				<Route path='/' element={<Layout />}>
					<Route index element={<Home />} />
					<Route path='login' element={<Login />} />
					<Route path='postovi' element={user ? <Posts /> : <Login />} />
					<Route path='galerija' element={<Gallery />} />
					<Route path='*' element={<NotFound />} />
				</Route>
			</Routes>
		</div>
	);
}

export default App;
