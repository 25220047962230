import { ApolloClient, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';

const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				mediaItems: relayStylePagination(),
				posts: relayStylePagination(),
			},
		},
	},
});

const client = new ApolloClient({
	uri: 'https://plase.link/wp-cms/graphql',
	cache,
});

export default client;
