import React, { useState } from 'react';
import { NavLink, Link, useNavigate } from 'react-router-dom';
import { useUserContext } from '../lib/UserProvider';

const Navbar = () => {
	const [isMobMenuOpen, setIsMobMenuOpen] = useState(false);
	const [user, setUser] = useUserContext();
	const navigate = useNavigate();

	const mobMenuBtnhandler = () => {
		setIsMobMenuOpen(!isMobMenuOpen);
	};
	return (
		<nav className='fixed left-0 top-0 w-full z-50 bg-gray-800'>
			<div className='lg:container mx-auto px-5 lg:px-0 h-24 flex justify-between items-center'>
				<div className='logo text-3xl text-white'>
					<Link to='/'>Plase Logo</Link>
				</div>
				<button
					type='button'
					className='inline-flex items-center justify-center rounded-md p-1 text-gray-200 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white md:hidden'
					aria-controls='mobile-menu'
					aria-expanded='false'
					onClick={mobMenuBtnhandler}
				>
					<span className='sr-only'>Open main menu</span>
					<svg
						className={`${isMobMenuOpen ? 'hidden' : 'block'} h-8 w-8`}
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth='2'
						stroke='currentColor'
						aria-hidden='true'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5'
						/>
					</svg>

					<svg
						className={`${isMobMenuOpen ? 'block' : 'hidden'} h-8 w-8`}
						fill='none'
						viewBox='0 0 24 24'
						strokeWidth='2'
						stroke='currentColor'
						aria-hidden='true'
					>
						<path
							strokeLinecap='round'
							strokeLinejoin='round'
							d='M6 18L18 6M6 6l12 12'
						/>
					</svg>
				</button>
				<div className='hidden sm:ml-6 md:block'>
					<div className='flex space-x-4'>
						<NavLink
							to='/'
							className={({ isActive }) =>
								isActive
									? 'text-green-300 uppercase font-medium'
									: 'text-white uppercase font-medium hover:text-green-600'
							}
						>
							Naslovna
						</NavLink>
						{user && (
							<NavLink
								to='postovi'
								className={({ isActive }) =>
									isActive
										? 'text-green-600 uppercase font-medium'
										: 'text-white uppercase font-medium hover:text-green-600'
								}
							>
								Postovi
							</NavLink>
						)}
						<NavLink
							to='galerija'
							className={({ isActive }) =>
								isActive
									? 'text-green-300 uppercase font-medium'
									: 'text-white uppercase font-medium hover:text-green-600'
							}
						>
							Galerija
						</NavLink>
						{user ? (
							<button
								type='button'
								onClick={() => {
									setUser(null);
									navigate('/login');
								}}
								className='text-white uppercase font-medium hover:text-green-600'
							>
								Logout
							</button>
						) : (
							<NavLink
								to='login'
								className={({ isActive }) =>
									isActive
										? 'text-green-600 uppercase font-medium'
										: 'text-white uppercase font-medium hover:text-green-600'
								}
							>
								Login
							</NavLink>
						)}
					</div>
				</div>
				<div
					className={`md:hidden absolute top-0 w-full h-screen bg-gray-700 z-40 ${
						isMobMenuOpen ? 'right-0' : '-right-full'
					} transition-all`}
					id='mobile-menu'
					onClick={mobMenuBtnhandler}
				>
					<div className='space-y-4 px-2 py-2 h-full flex flex-col justify-center items-center'>
						<NavLink
							to='/'
							className={({ isActive }) =>
								isActive
									? 'text-green-600 uppercase font-medium'
									: 'text-white uppercase font-medium hover:text-green-600'
							}
						>
							Naslovna
						</NavLink>
						<NavLink
							to='galerija'
							className={({ isActive }) =>
								isActive
									? 'text-green-300 uppercase font-medium'
									: 'text-white uppercase font-medium hover:text-green-600'
							}
						>
							Galerija
						</NavLink>
						{user && (
							<NavLink
								to='postovi'
								className={({ isActive }) =>
									isActive
										? 'text-green-600 uppercase font-medium'
										: 'text-white uppercase font-medium hover:text-green-600'
								}
							>
								Postovi
							</NavLink>
						)}
						{user ? (
							<button
								type='button'
								onClick={() => {
									setUser(null);
									navigate('/login');
								}}
								className='text-white uppercase font-medium hover:text-green-600'
							>
								Logout
							</button>
						) : (
							<NavLink
								to='login'
								className={({ isActive }) =>
									isActive
										? 'text-green-600 uppercase font-medium'
										: 'text-white uppercase font-medium hover:text-green-600'
								}
							>
								Login
							</NavLink>
						)}
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
