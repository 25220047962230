import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

const SignupSchema = Yup.object().shape({
	firstName: Yup.string().required('Obavezno unijeti ime'),
	lastName: Yup.string().required('Obavezno unijeti prezime'),
	email: Yup.string()
		.email('Unesite validnu email adresu')
		.required('Obavezno unijeti email'),
});
const url = 'https://plase.link/';
const ContactForm = () => {
	const [contactData, setContactData] = useState('');
	return (
		<div className='border-b border-gray-900/10 w-full sm:col-span-3 relative z-10'>
			<h2 className='text-3xl font-semibold leading-7 text-white'>
				Lične informacije
			</h2>

			<div className='mt-3 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6'>
				<div className='sm:col-span-6'>
					<Formik
						initialValues={{
							firstName: '',
							lastName: '',
							email: '',
							phone: '',
							message: '',
						}}
						validationSchema={SignupSchema}
						onSubmit={async (values, { setSubmitting, resetForm }) => {
							// same shape as initial values
							const formdata = new FormData();
							formdata.append('first_name', values.firstName);
							formdata.append('last_name', values.lastName);
							formdata.append('email', values.email);
							formdata.append('phone', values.phone);
							formdata.append('message', values.message);
							const res = await axios.post(`${url}/api/contact.php`, formdata);
							const data = res.data;
							console.log(data);
							setContactData(data);
							setTimeout(() => {
								setContactData('');
							}, 3000);
							setSubmitting(false);
							resetForm();
						}}
					>
						{({ values, errors, touched }) => (
							<Form className='mt-3 grid grid-cols-1 gap-x-6 gap-y-2 md:gap-y-8 sm:grid-cols-6'>
								<div className='sm:col-span-6 md:col-span-3'>
									<label
										htmlFor='firstName'
										className='block font-medium leading-6 text-md text-white'
									>
										Ime*
									</label>
									<div className='mt-2'>
										<Field
											id='firstName'
											name='firstName'
											type='text'
											autoComplete='firstName'
											value={values.firstName}
											required
											className='block w-full rounded-md border-0 py-1.5 pl-2 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
										/>
									</div>
									{errors.firstName && touched.firstName ? (
										<div className='text-red-500 font-medium'>
											{errors.firstName}
										</div>
									) : null}
								</div>
								<div className='sm:col-span-6 md:col-span-3'>
									<label
										htmlFor='lastName'
										className='block text-md text-md text-white font-medium leading-6'
									>
										Prezime*
									</label>
									<div className='mt-2'>
										<Field
											id='lastName'
											name='lastName'
											type='text'
											autoComplete='lastName'
											value={values.lastName}
											required
											className='block w-full rounded-md border-0 py-1.5 pl-2 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
										/>
									</div>
									{errors.lastName && touched.lastName ? (
										<div className='text-red-500 font-medium'>
											{errors.lastName}
										</div>
									) : null}
								</div>
								<div className='sm:col-span-6 md:col-span-3'>
									<label
										htmlFor='email'
										className='block text-md text-white font-medium leading-6'
									>
										Email adresa*
									</label>
									<div className='mt-2'>
										<Field
											id='email'
											name='email'
											type='email'
											autoComplete='email'
											value={values.email}
											required
											className='block w-full rounded-md border-0 py-1.5 pl-2 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
										/>
									</div>
									{errors.email && touched.email ? (
										<div className='text-red-500 font-medium'>
											{errors.email}
										</div>
									) : null}
								</div>

								<div className='sm:col-span-6 md:col-span-3'>
									<div className='flex items-center justify-between'>
										<label
											htmlFor='phone'
											className='block text-md text-white font-medium leading-6'
										>
											Telefon
										</label>
									</div>
									<div className='mt-2'>
										<Field
											id='phone'
											name='phone'
											type='tel'
											autoComplete='phone'
											value={values.phone}
											className='block w-full rounded-md border-0 py-1.5 pl-2 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
										/>
									</div>
									{errors.phone && touched.phone ? (
										<div className='text-red-500 font-medium'>
											{errors.phone}
										</div>
									) : null}
								</div>
								<div className='sm:col-span-6'>
									<div className='flex items-center justify-between'>
										<label
											htmlFor='message'
											className='block text-md text-white font-medium leading-6'
										>
											Poruka
										</label>
									</div>
									<div className='mt-2'>
										<Field
											id='message'
											name='message'
											as='textarea'
											value={values.message}
											className='block w-full rounded-md border-0 py-1.5 pl-2 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
										/>
									</div>
									{errors.phone && touched.phone ? (
										<div className='text-red-500 font-medium'>
											{errors.phone}
										</div>
									) : null}
								</div>

								<div className='sm:col-span-6'>
									<button
										type='submit'
										className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
									>
										Pošalji
									</button>
								</div>
								{contactData && (
									<p className='text-lg text-gray-200 col-span-6'>
										{contactData}
									</p>
								)}
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
};

export default ContactForm;
