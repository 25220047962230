import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Helmet } from 'react-helmet-async';
import Loading from '../images/loading.svg';

const GET_ALL_POSTS = gql`
	query GetPostsEdges {
		posts {
			edges {
				node {
					id
					title
					date
				}
			}
		}
	}
`;

const Posts = () => {
	const { loading, error, data } = useQuery(GET_ALL_POSTS);
	console.log(loading, error, data);

	if (loading) {
		return (
			<div className='lg:container mx-auto px-5 flex h-64 justify-center items-center'>
				<img src={Loading} alt='Loader' className='max-h-full' />
			</div>
		);
	}
	if (error) {
		return <div>Something went wrong!!!</div>;
	}
	return (
		<>
			<Helmet>
				<title>Postovi - Plase</title>

				<meta
					name='description'
					content='How much does culture influence creative thinking?'
				/>

				<meta property='og:type' content='website' />
				<meta property='og:title' content='Naslovna - Plase' />
				<meta
					property='og:description'
					content='Ova stranica je namijenjena internim temama porodica Zorić i
					Vignjević iz Potkrša, selo Lipa kod Bihaća.'
				/>
			</Helmet>
			<div>
				{data?.posts.edges.map((item) => (
					<div key={item.node.id}>
						<h2>{item.node.title}</h2>
						<p>{new Date(item.node.date).toLocaleDateString()}</p>
					</div>
				))}
			</div>
		</>
	);
};

export default Posts;
