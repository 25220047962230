import { createContext, useState, useContext } from 'react';

const UserContext = createContext();

export function useUserContext() {
	const context = useContext(UserContext);
	if (!context) {
		throw new Error('useUserContext mora se koristiti unutar UserProvider-a');
	}
	return context;
}

const UserProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const value = [user, setUser];
	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
