import React from 'react';
import { Helmet } from 'react-helmet-async';
import HomeHeader from '../components/HomeHeader';
import ZoricTree from '../components/ZoricTree';
import VignjevicTree from '../components/VignjevicTree';

const Home = () => {
	return (
		<>
			<Helmet>
				<title>Naslovna - Plase</title>

				<meta
					name='description'
					content='How much does culture influence creative thinking?'
				/>

				<meta property='og:type' content='website' />
				<meta property='og:title' content='Naslovna - Plase' />
				<meta
					property='og:description'
					content='Ova stranica je namijenjena internim temama porodica Zorić i
					Vignjević iz Potkrša, selo Lipa kod Bihaća.'
				/>
			</Helmet>
			<div className='w-full'>
				<HomeHeader />
				<ZoricTree />
				<VignjevicTree />
			</div>
		</>
	);
};

export default Home;
