import React from 'react';
import { zoric } from '../data/json';
import FamilyTree from './FamilyTree';

const ZoricTree = () => {
	return (
		<section className='lg:container mx-auto px-5 my-8 overflow-x-auto'>
			<h2 className='text-2xl'>Porodično stablo Zorića</h2>
			<FamilyTree data={zoric} width={1920} height={1200} />
		</section>
	);
};

export default ZoricTree;
