import React from 'react';
import { vignjevic } from '../data/json';
import FamilyTree from './FamilyTree';

const VignjevicTree = () => {
	return (
		<section className='lg:container mx-auto px-5 my-8 overflow-x-auto'>
			<h2 className='text-2xl'>Porodično stablo Vignjevića</h2>
			<FamilyTree data={vignjevic} width={3500} height={4500} />
		</section>
	);
};

export default VignjevicTree;
