import React from 'react';

const Footer = () => {
	return (
		<footer className='bg-gray-800 py-8 flex justify-center items-center text-white'>
			<p>&copy; 2023 Plase | All rights reserved</p>
		</footer>
	);
};

export default Footer;
