import React, { useState, useEffect } from 'react';
import { ParallaxBanner } from 'react-scroll-parallax';
import HomeLandscape from '../images/lipa.webp';
import HomePortrait from '../images/home-portrait-min.webp';
import ContactForm from './ContactForm';

const HomeHeader = () => {
	const [viewportWidth, setViewportWidth] = useState(0);

	const calculateViewportWidth = () => {
		const width = window.innerWidth;
		setViewportWidth(width);
	};
	useEffect(() => {
		calculateViewportWidth();
		window.addEventListener('resize', calculateViewportWidth);
		return () => {
			window.removeEventListener('resize', calculateViewportWidth);
		};
	}, []);
	return (
		<ParallaxBanner
			layers={[
				{
					image: viewportWidth < 600 ? HomePortrait : HomeLandscape,
					speed: -20,
				},
			]}
			className='h-full'
		>
			<header className='w-full bg-no-repeat bg-cover'>
				<span className='absolute w-full h-full left-0 top-0 bg-slate-600 bg-opacity-70'></span>
				<div className='min-h-[calc(100vh-96px)] py-8 lg:container px-5 mx-auto grid grid-cols-1 sm:grid-cols-6 gap-x-6 justify-end items-center relative z-10'>
					<div className='sm:col-span-3'>
						<h1 className='text-white font-semibold text-3xl lg:text-4xl mb-4'>
							Sajt porodica Zorić i Vignjević
						</h1>
						<p className='mt-1 text-lg font-medium leading-6 text-white'>
							Ova stranica je namijenjena internim temama porodica Zorić i
							Vignjević iz Potkrša, selo Lipa kod Bihaća. Ukoliko imate neko
							pitanje u vezi ovih porodica, možete nam pisati putem kontakt
							forme.
						</p>
					</div>
					<ContactForm />
				</div>
			</header>
		</ParallaxBanner>
	);
};

export default HomeHeader;
