import React, { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';

import Fancybox from '../components/Fancybox';
import { Helmet } from 'react-helmet-async';
import Loading from '../images/loading.svg';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { FaArrowCircleRight } from 'react-icons/fa';

const GET_ALL_MEDIA = gql`
	query GetMedia($after: String, $before: String, $first: Int!) {
		mediaItems(after: $after, before: $before, first: $first) {
			pageInfo {
				endCursor
				hasNextPage
				hasPreviousPage
				startCursor
			}
			nodes {
				mediaDetails {
					file
					height
					width
				}
				srcSet
				sourceUrl
				altText
				id
			}
		}
	}
`;

const BATCH_SIZE = 12;

const Gallery = () => {
	const { loading, error, data, fetchMore } = useQuery(GET_ALL_MEDIA, {
		variables: { after: null, before: null, first: BATCH_SIZE },
		notifyOnNetworkStatusChange: true,
	});
	console.log(data);
	const haveMoreMedia = Boolean(
		data?.mediaItems?.pageInfo?.hasPreviousPage ||
			data?.mediaItems?.pageInfo?.hasNextPage
	);

	const content = useMemo(() => {
		const media = data && data?.mediaItems.nodes.map((node) => node);
		if (loading) {
			return (
				<div className='w-full flex justify-center items-center h-[calc(100vh-183px)]'>
					<img src={Loading} alt='Loader' className='max-h-full' />
				</div>
			);
		}
		return (
			media &&
			!loading &&
			media?.map((node) => {
				const { sourceUrl, id, srcSet, altText } = node;
				return (
					<a data-fancybox='gallery' href={sourceUrl} key={id}>
						<img srcSet={srcSet} alt={altText} className='mb-4' />
					</a>
				);
			})
		);
	}, [data, loading]);

	if (!data && loading) {
		return (
			<div className='lg:container mx-auto px-5 flex justify-center items-center h-[calc(100vh-183px)]'>
				<img src={Loading} alt='Loader' className='max-h-full' />
			</div>
		);
	}
	if (error) {
		return <div>Something went wrong!!!</div>;
	}
	return (
		<>
			<Helmet>
				<title>Galerija - Plase</title>

				<meta
					name='description'
					content='Ova stranica je namijenjena internim temama porodica Zorić i
					Vignjević iz Potkrša, selo Lipa kod Bihaća.'
				/>

				<meta property='og:type' content='website' />
				<meta property='og:title' content='Galerija - Plase' />
				<meta
					property='og:description'
					content='Ova stranica je namijenjena internim temama porodica Zorić i
					Vignjević iz Potkrša, selo Lipa kod Bihaća.'
				/>
			</Helmet>
			<div className='lg:container mx-auto px-5 py-12'>
				<h1 className='text-2xl lg:text-5xl mb-4'>Galerija</h1>
				<div className='columns-2 md:columns-3 lg:columns-4'>
					<Fancybox>{content}</Fancybox>
				</div>
				{haveMoreMedia && (
					<div className='lg:container mx-auto px-5 flex justify-center gap-x-5 mt-6'>
						<form
							method='post'
							onSubmit={(e) => {
								e.preventDefault();
								fetchMore({
									variables: {
										before: data?.mediaItems.pageInfo.startCursor,
									},
								});
							}}
						>
							<button
								type='submit'
								disabled={!data?.mediaItems.pageInfo.hasPreviousPage}
								className='text-green-500 text-5xl disabled:text-gray-400 hover:text-green-600'
							>
								<FaArrowCircleLeft />
							</button>
						</form>
						<form
							method='post'
							onSubmit={(e) => {
								e.preventDefault();
								fetchMore({
									variables: {
										after: data?.mediaItems.pageInfo.endCursor,
									},
								});
								console.log(data?.mediaItems.pageInfo.endCursor);
							}}
						>
							<button
								type='submit'
								disabled={!data?.mediaItems.pageInfo.hasNextPage}
								className='text-green-500 text-5xl disabled:text-gray-400 hover:text-green-600'
							>
								<FaArrowCircleRight />
							</button>
						</form>
					</div>
				)}
			</div>
		</>
	);
};

export default Gallery;
